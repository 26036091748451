import React from "react";
import Lead from "../components/sections/lead";
import brandsMobileLead from "../images/brands-lead-bg--mobile@1x.jpg";
import brandsLead from "../images/brands-lead-bg@1x.jpg";
import BrandSection from "../components/sections/brandSection";
import ampm from "../images/ampm@1x.jpg";
import ampmWebp from "../images/ampm@1x.webp";
import ampmMobile from "../images/ampm-brand--mobile@1x.jpg";
import ampmMobileWebp from "../images/ampm-brand--mobile@1x.webp";
import ampmLogo from "../images/ampm.svg";
import moveToAmpm from "../images/discover-ampm.svg";
import damixa from "../images/damixa@1x.jpg";
import damixaWebp from "../images/damixa@1x.webp";
import damixaMobile from "../images/damixa-brand--mobile@1x.jpg";
import damixaMobileWebp from "../images/damixa-brand--mobile@1x.webp";
import damixaBlack from "../images/damixa-black.svg";
import moveToDamixa from "../images/discover-damixa--black.svg";
import Feedback from "../components/sections/feedback";
import Footer from "../components/sections/footer";
import leadVideo from "../video/brands.mp4";
import {t} from "i18next";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";

const Brands = () => {
  const {t, i18n} = useTranslation();

  return (
    <>
      <Helmet>
        <html lang='ru' />
        <title>{t('lead.seo.brands')}</title>
      </Helmet>
      <Lead
        subtitle={t('lead.our-brands.pre-title')}
        title={t('lead.our-brands.main-text')}
        leadMobileImage={brandsMobileLead}
        leadImage={brandsLead}
        leadVideo={leadVideo}
      />
      <BrandSection
        className={`brand--ampm`}
        logo={ampmLogo}
        bigTextClass={`big-text__ampm`}
        text={t('lead.our-brands.ampm')}
        imgMove={moveToAmpm}
        mobileImage={ampmMobile}
        mobileImageWebp={ampmMobileWebp}
        image={ampm}
        imageWebp={ampmWebp}
        link={`/ampm/`}
        arrowDirection={`arrow-down--right`}
      />
      <BrandSection
        className={`brand--damixa`}
        logo={damixaBlack}
        text={t('lead.our-brands.damixa')}
        bigTextClass={`big-text__damixa big-text--black`}
        imgMove={moveToDamixa}
        mobileImage={damixaMobile}
        mobileImageWebp={damixaMobileWebp}
        image={damixa}
        imageWebp={damixaWebp}
        link={`/damixa/`}
        arrowDirection={`arrow-down--black arrow-down--right`}
      />
      <Feedback />
      <Footer />
    </>
  );
};

export default Brands;
